<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col v-if="existe" cols="12">
      <!-- TITULO EVALUACION -->
      <v-card v-if="!finalizada" class="mb-3">
        <v-card-title class="text-h5" style="word-break: normal">
          <v-icon left>far fa-lightbulb</v-icon>
          Consigna {{ autoevaluacion == 1 ? 'auto' : '' }} evaluación
        </v-card-title>
        <v-card-text class="body-1">
          {{ autoevaluacion == 1 ? `
          A continuación te presentamos diferentes competencias del puesto a evaluar, te pedimos que las leas detenidamente y a conciencia para realizar una
          auto valoración lo más objetiva posible de tu desempeño. A cada competencia podrás asignarle un valor de 1 a 5, teniendo en cuenta que 1 es el
          puntaje menor y 5 el puntaje mayor.
          ` : `
          A continuación te presentamos diferentes competencias del puesto a evaluar, te pedimos que las leas detenidamente y a conciencia para realizar una
          valoración lo más objetiva posible del desempeño de la persona en cuestión. A cada competencia podrás asignarle un valor de 1 a 5, teniendo en
          cuenta que 1 es el puntaje menor  y 5 el puntaje mayor.
          ` }}
        </v-card-text>
      </v-card>
      <!-- COMPETENCIAS -->
      <v-card>
        <v-card-title class="text-h5" style="word-break: normal">
          <v-icon left>fas fa-edit</v-icon>
          Evaluado: {{ nombre }}
        </v-card-title>
        <v-card-text v-if="tabla_competencias.length == 0" class="body-1">
          <v-card
            v-for="(item, index) in competencias"
            :key="index"
            flat
          >
            <v-card-title class="pa-0" style="word-break: normal">
              {{ item.titulo }}
              <v-spacer></v-spacer>
              <v-rating
                v-model="item.puntaje"
                background-color="orange lighten-3"
                color="orange"
                :readonly="competencias_bk.find(comp => comp.id_comp == item.id_comp).puntaje != null"
                hover
              ></v-rating>
            </v-card-title>
            <v-card-text class="px-0">
              {{ item.descripcion }}
            </v-card-text>
          </v-card>
        </v-card-text>
        <!-- VISTA DE DATA TABLE -->
        <v-card-text v-else class="body-1 pa-0">
          <v-data-table
            :items="tabla_competencias"
            :headers="headers"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <!-- info de la competencia -->
            <template v-slot:[`item.competencia`]="{ item }">
              <div class="d-flex">
                {{ item.nombre }}
                <v-spacer></v-spacer>
                <v-tooltip
                  max-width="500"
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      style="cursor: pointer"
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  {{ item.descripcion }}
                </v-tooltip>
              </div>
            </template>
            <!-- clasificacion x evaluador -->
            <template
              v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('star'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              <strong :key="index">
                {{ value }}
                <v-icon color="orange" class="mb-1" small>fas fa-star</v-icon>
              </strong>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <!-- OBJETIVOS -->
      <v-row v-if="objetivos.length > 0" class="d-flex justify-center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="mt-3">
            <v-card-title class="text-h5" style="word-break: normal">
              <v-icon left>fas fa-chart-bar</v-icon>
              Objetivos
            </v-card-title>
            <v-card-text>
              <v-data-table
                sort-by="periodo"
                :item-class="itemRowBackground"
                :items="objetivos"
                :headers="[
                  { text: 'Periodo', value: 'periodo', sortable: false },
                  { text: 'Alcance', value: 'objetivo', align: 'center', sortable: false }
                ]"
                :items-per-page="-1"
                hide-default-footer
                dense
              >
                <template v-slot:[`item.periodo`]="{ item }">
                  {{ moment(item.periodo).format('MM/YYYY') }}
                </template>
                <template v-slot:[`item.objetivo`]="{ item }">
                  {{ item.objetivo }}%
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- COMPETENCIAS DESTACADAS Y A MEJORAR -->
      <v-card class="mt-3">
        <v-card-title class="text-h5" style="word-break: normal">
          <v-icon left>fas fa-user-graduate</v-icon>
          Valoración de competencias
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="competencias_destacadas"
                :label="valoracion_competencias.length == 0 ? 'Destacadas' : (autoevaluacion == 1 ? 'Destacadas (Auto evaluación)' : `Destacadas (por ${nombre_evaluador})` )"
                item-text="titulo"
                item-value="id_comp"
                :items="competencias.filter(comp => comp.destacada == 1 || (comp.destacada == 0 && comp.a_mejorar == 0))"
                :disabled="!completada"
                :readonly="finalizada"
                :filled="!completada || finalizada"
                deletable-chips
                hide-details
                small-chips
                outlined
                multiple
                dense
                @change="destacar()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="competencias_mejorar"
                :label="valoracion_competencias.length == 0 ? 'A trabajar' : (autoevaluacion == 1 ? 'A trabajar (Auto evaluación)' : `A trabajar (por ${nombre_evaluador})` )"
                item-text="titulo"
                item-value="id_comp"
                :items="competencias.filter(comp => comp.a_mejorar == 1 || (comp.destacada == 0 && comp.a_mejorar == 0))"
                :disabled="!completada"
                :readonly="finalizada"
                :filled="!completada || finalizada"
                deletable-chips
                hide-details
                small-chips
                outlined
                multiple
                dense
                @change="trabajar()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, index) in valoracion_competencias"
            :key="index"
          >
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="item.destacadas"
                :label="`Destacadas (${item.nombre})`"
                item-text="titulo"
                item-value="id_comp"
                :items="competencias"
                hide-details
                small-chips
                outlined
                multiple
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="item.a_mejorar"
                :label="`A trabajar (${item.nombre})`"
                item-text="titulo"
                item-value="id_comp"
                :items="competencias"
                hide-details
                small-chips
                outlined
                multiple
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- ACUERDOS DE MEJORA -->
      <v-card v-if="finalizada || autoevaluacion != 1" class="mt-3">
        <v-card-title class="text-h5" style="word-break: normal">
          <div>
            <v-icon left>fas fa-handshake</v-icon>
            Sugerencia{{ autoevaluacion == 1 ? 's' : '' }} de mejora
            <span v-if="autoevaluacion != 1" class="body-1">
              (Instancia de feedback dónde podrás hacer propuestas a {{ nombre }})
            </span>
          </div>
        </v-card-title>
        <v-card-text v-if="autoevaluacion == 1">
          <v-textarea
            v-for="(item, index) in acuerdos"
            :key="index"
            v-model="item.acuerdo"
            :label="`Acordada con ${item.nombre}`"
            rows="3"
            hide-details
            auto-grow
            outlined
            readonly
            filled
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-text v-else>
          <v-textarea
            v-model="acuerdo"
            rows="3"
            :disabled="!completada"
            :filled="!completada"
            hide-details
            auto-grow
            outlined
            dense
          ></v-textarea>
        </v-card-text>
      </v-card>
      <!-- BOTONES -->
      <v-row class="d-flex justify-end py-6 px-3">
        <BtnConfirmar
          texto="¿Desea guardar la evaluación? (Las competencias ya evaluadas no podrán ser editadas)"
          titulo="Guardar cambios"
          @action="guardar()"
        />
        <BtnConfirmar
          v-if="!finalizada"
          nombre="Guardar y Finalizar"
          titulo="Finalizar evaluación"
          texto="¿Desea guardar y finalizar la evaluación? (Una vez finalizada no podrá editar el puntaje ni la valoración de las competencias)"
          clase="ml-2"
          color="primary"
          icono="fas fa-flag-checkered"
          :disabled="!completada"
          @action="guardar_y_finalizar()"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BtnConfirmar from '../../components/util/BtnConfirmar'
import moment from 'moment'

export default {
  data () {
    return {
      id: this.$route.params.id,
      moment: moment,
      autoevaluacion: null,
      existe: false,
      nombre: '',
      nombre_evaluador: '',
      finalizada: false,
      completada: false,
      acuerdo: null,
      acuerdo_bk: null,
      headers: [],
      tabla_competencias: [],
      valoracion_competencias: [],
      acuerdos: [],
      objetivos: [],
      competencias: [],
      competencias_destacadas: [],
      competencias_mejorar: [],
      competencias_bk: [],
      competencias_destacadas_bk: [],
      competencias_mejorar_bk: []
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('evaluaciones/get_evaluacion', this.id)
      .then((res) => {
        this.finalizada = res.data.finalizada == 1
        this.autoevaluacion = res.data.autoevaluacion
        this.valoracion_competencias = res.data.valoracion_competencias
        this.tabla_competencias = res.data.tabla_competencias
        this.headers = res.data.tabla_headers
        this.competencias = res.data.competencias
        this.objetivos = res.data.objetivos
        this.acuerdos = res.data.acuerdos
        this.acuerdo = res.data.acuerdo
        this.acuerdo_bk = JSON.stringify(res.data.acuerdo)
        this.completada = this.competencias.filter(comp => comp.puntaje == null).length == 0
        this.competencias_bk = JSON.parse(JSON.stringify(this.competencias))
        this.agrupar_competencias()
        this.nombre = res.data.nombre
        this.nombre_evaluador = res.data.nombre_evaluador
        this.existe = true
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  },
  components: {
    BtnConfirmar
  },
  watch: {
    competencias: {
      handler: function () {
        this.completada = this.competencias.filter(comp => comp.puntaje == null).length == 0
      },
      deep: true
    }
  },
  methods: {
    itemRowBackground (item) {
      return item.objetivo > 100 ? 'background-color: cardSuccess' : (item.objetivo < 80 ? 'background-color: rowerr' : '')
    },
    async finalizar () {
      await this.$store.dispatch('evaluaciones/finalizar', this.id)
        .then(async (res) => {
          this.finalizada = true
          await this.$swal.fire({
            icon: 'success',
            title: res.message
          })
          window.location.reload(true)
        })
        .catch(error => {
          this.$store.state.loading = false
          return this.$swal.fire({
            icon: 'error',
            title: error.message
          })
        })
    },
    async verificar_acuerdo () {
      if ((JSON.stringify(this.acuerdo) != this.acuerdo_bk)) {
        await this.$store.dispatch('evaluaciones/guardar_acuerdo', {
          id_eval: this.id,
          acuerdo: this.acuerdo
        })
          .then(async () => {
            this.acuerdo_bk = JSON.stringify(this.acuerdo)

            // finalizo la evaluacion
            await this.finalizar()
          })
          .catch(error => {
            this.$store.state.loading = false
            return this.$swal.fire({
              icon: 'error',
              title: error.message
            })
          })
      }
      else {
        // finalizo la evaluacion
        await this.finalizar()
      }
    },
    async guardar_y_finalizar () {
      this.$store.state.loading = true
      if (!this.completada) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Complete la evaluación antes de finalizarla',
          color: 'orange'
        })
      }

      // previamente debo verificar si el completar se puso por el watch o ya vino
      if (this.competencias_bk.filter(comp => comp.puntaje == null).length > 0) {
        let competencias = []
        // obtengo las competencias que completó
        for (let index = 0; index < this.competencias.length; index++) {
          const competencia = this.competencias[index]
          const competencia_bk = this.competencias_bk[index]
            
          if (competencia_bk.puntaje == null && competencia.puntaje != null) {
            competencias.push({
              id_comp: competencia.id_comp,
              puntaje: competencia.puntaje
            })
          }
        }

        if (competencias.length > 0) {
          await this.$store.dispatch('evaluaciones/guardar_evaluacion', {
            id_eval: this.id,
            competencias: competencias
          })
            .then(() => {
              this.competencias_bk = JSON.parse(JSON.stringify(this.competencias))
            })
            .catch(error => {
              this.$store.state.loading = false
              return this.$swal.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      }

      // verifico si modifico las competencias
      if ((JSON.stringify(this.competencias_mejorar) != JSON.stringify(this.competencias_mejorar_bk)) ||
          (JSON.stringify(this.competencias_destacadas) != JSON.stringify(this.competencias_destacadas_bk))) {
        
        await this.$store.dispatch('evaluaciones/destacar_competencias', {
          id_eval: this.id,
          a_destacar: this.competencias_destacadas,
          a_mejorar: this.competencias_mejorar
        })
          .then(async () => {
            this.competencias_destacadas_bk = JSON.parse(JSON.stringify(this.competencias_destacadas))
            this.competencias_mejorar_bk = JSON.parse(JSON.stringify(this.competencias_mejorar))
            
            // verifico si modifico el acuerdo
            await this.verificar_acuerdo()

          })
          .catch(error => {
            this.$store.state.loading = false
            return this.$swal.fire({
              icon: 'error',
              title: error.message
            })
          })
      }
      // si no modifico las competencias verifico si modifico el acuerdo
      else {
        await this.verificar_acuerdo()
      }
      this.$store.state.loading = false
    },
    destacar () {
      for (const comp of this.competencias_destacadas) {
        let competencia = this.competencias.find(com => com.id_comp == comp)
        competencia.destacada = 1
        competencia.a_mejorar = 0
      }
      for (let competencia of this.competencias) {
        const comp = this.competencias_destacadas.find(com => com == competencia.id_comp)
        if (!comp) competencia.destacada = 0
      }
    },
    trabajar () {
      for (const comp of this.competencias_mejorar) {
        let competencia = this.competencias.find(com => com.id_comp == comp)
        competencia.a_mejorar = 1
        competencia.destacada = 0
      }
      for (let competencia of this.competencias) {
        const comp = this.competencias_mejorar.find(com => com == competencia.id_comp)
        if (!comp) competencia.a_mejorar = 0
      }
    },
    agrupar_competencias () {
      this.competencias_destacadas = []
      this.competencias_mejorar = []
      for (const competencia of this.competencias) {
        if (competencia.destacada == 1 && competencia.a_mejorar == 0) {
          this.competencias_destacadas.push(competencia.id_comp)
        }
        if (competencia.destacada == 0 && competencia.a_mejorar == 1) {
          this.competencias_mejorar.push(competencia.id_comp)
        }
      }
      this.competencias_destacadas_bk = JSON.parse(JSON.stringify(this.competencias_destacadas))
      this.competencias_mejorar_bk = JSON.parse(JSON.stringify(this.competencias_mejorar))
    },
    async guardar () {
      this.$store.state.loading = true
      // si no esta completada guardo las competencias
      if (!this.completada) {
        let competencias = []

        // obtengo las competencias que completó
        for (let index = 0; index < this.competencias.length; index++) {
          const competencia = this.competencias[index]
          const competencia_bk = this.competencias_bk[index]
          
          if (competencia_bk.puntaje == null && competencia.puntaje != null) {
            competencias.push({
              id_comp: competencia.id_comp,
              puntaje: competencia.puntaje
            })
          }
        }

        if (competencias.length == 0) {
          this.$store.state.loading = false
          return this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en la evaluación',
            color: 'warning'
          })
        }

        await this.$store.dispatch('evaluaciones/guardar_evaluacion', {
          id_eval: this.id,
          competencias: competencias
        })
          .then(async (res) => {
            this.completada = this.competencias.filter(comp => comp.puntaje == null).length == 0
            this.competencias_bk = JSON.parse(JSON.stringify(this.competencias))

            await this.$swal.fire({
              icon: 'success',
              title: res.message
            })
            window.location.reload(true)
          })
          .catch(error => {
            this.$swal.fire({
              icon: 'error',
              title: error.message
            })
          })
      }
      // si ya completo las competencias guardo las destacadas y a trabajar
      else {
        // previamente debo revisar si el completada se puso por el watch o ya estaba completada cuando entró a la evaluacion
        // si se puso por el watch hay que guardar las competencias que quedaron sin guardarse, sino solo guardar el resto
        let guardo_competencias = false
        if (this.competencias_bk.filter(comp => comp.puntaje == null).length > 0) {
          let competencias = []
          // obtengo las competencias que completó
          for (let index = 0; index < this.competencias.length; index++) {
            const competencia = this.competencias[index]
            const competencia_bk = this.competencias_bk[index]
            
            if (competencia_bk.puntaje == null && competencia.puntaje != null) {
              competencias.push({
                id_comp: competencia.id_comp,
                puntaje: competencia.puntaje
              })
            }
          }

          if (competencias.length > 0) {
            await this.$store.dispatch('evaluaciones/guardar_evaluacion', {
              id_eval: this.id,
              competencias: competencias
            })
              .then(() => {
                guardo_competencias = true
                this.competencias_bk = JSON.parse(JSON.stringify(this.competencias))
              })
              .catch(error => {
                this.$store.state.loading = false
                return this.$swal.fire({
                  icon: 'error',
                  title: error.message
                })
              })
          }
        }

        let success = []
        let errores = []

        // solo guardo si modifico las competencias
        if ((JSON.stringify(this.competencias_mejorar) != JSON.stringify(this.competencias_mejorar_bk)) ||
            (JSON.stringify(this.competencias_destacadas) != JSON.stringify(this.competencias_destacadas_bk))) {
          await this.$store.dispatch('evaluaciones/destacar_competencias', {
            id_eval: this.id,
            a_destacar: this.competencias_destacadas,
            a_mejorar: this.competencias_mejorar
          })
            .then((res) => {
              this.competencias_destacadas_bk = JSON.parse(JSON.stringify(this.competencias_destacadas))
              this.competencias_mejorar_bk = JSON.parse(JSON.stringify(this.competencias_mejorar))
              success.push(res.message)
            })
            .catch(error => {
              errores.push(error.message)
            })
        }

        if ((JSON.stringify(this.acuerdo) != this.acuerdo_bk)) {
          await this.$store.dispatch('evaluaciones/guardar_acuerdo', {
            id_eval: this.id,
            acuerdo: this.acuerdo
          })
            .then((res) => {
              this.acuerdo_bk = JSON.stringify(this.acuerdo)
              success.push(res.message)
            })
            .catch(error => {
              errores.push(error.message)
            })
        }

        // muestro mensaje de exito o error
        if (success.length == 2 && errores.length == 0) {
          await this.$swal.fire({
            icon: 'success',
            title: 'Cambios guardados correctamente'
          })
          window.location.reload(true)
        }
        else if (success.length == 1 && errores.length == 0) {
          await this.$swal.fire({
            icon: 'success',
            title: guardo_competencias ? 'Cambios guardados correctamente' : success[0]
          })
          window.location.reload(true)
        }
        else if (success.length == 0 && errores.length == 0) {
          if (guardo_competencias) {
            await this.$swal.fire({
              icon: 'success',
              title: 'Cambios guardados correctamente'
            })
            window.location.reload(true)
          }
          else {
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaon cambios',
              color: 'orange'
            })
          }
        }
        else if (success.length == 0 && errores.length == 1) {
          this.$swal.fire({
            icon: 'error',
            title: errores[0]
          })
        }
        else if (success.length == 0 && errores.length == 2) {
          this.$swal.fire({
            icon: 'error',
            title: errores[0] + '\n' + errores[1]
          })
        }
        else {
          this.$swal.fire({
            icon: 'warning',
            title: success[0],
            text: errores[0]
          })
        }
      }
      this.$store.state.loading = false
    }
  }
}
</script>